import { makeComparator } from '@/helpers/helpers';
import { getIdb, idbHelpers } from '@/idb';
import PaymentTerm from '@/models/PaymentTerm';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';
import cache from './cache';

const idbStore = 'paymentTerms';

export default {
	/**
	 * Get all payment terms
	 * @returns (async) Returns an array of PaymentTerm objects if the request was successful, otherwise a Response.
	 */
	async getAll() {
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchAllPages('/api/PaymentTerms', x => data.push(x));
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.getAll(idbStore);
			response = idbResponse(200);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idbHelpers.replaceAll(idb, idbStore, data);
				await cache.setTimestamp(idbStore, timestamp);
			}
			data.sort(makeComparator('sortOrder', 'id'));
			return data.map(x => new PaymentTerm(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a payment term
	 * @param {Number} id PaymentTerm ID
	 * @returns (async) Returns a PaymentTerm if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		const idb = await getIdb();
		let response, data = null;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb && await cache.canCache()) {
			// cache all for future re-use
			if ((data = (await this.getAll()).find(x => x.id === id) ?? null)) {
				return data;
			} else {
				throw idbResponse(404);
			}
		}
		if (!useIdb) {
			try {
				response = await fetchWrap('/api/PaymentTerms/' + id);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.get(idbStore, id);
			response = data ? idbResponse(200) : idbResponse(404);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idb.put(idbStore, data);
			}
			return new PaymentTerm(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a payment term
	 * @param {PaymentTerm} model payment term to create.
	 * @returns (async) Returns the new PaymentTerm if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/PaymentTerms', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return new PaymentTerm(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a payment term
	 * @param {PaymentTerm} model payment term to update.
	 * @returns (async) Returns the updated PaymentTerm if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/PaymentTerms/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return new PaymentTerm(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a payment term
	 * @param {Number} id PaymentTerm ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the payment term could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/PaymentTerms/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return true;
		} else if (response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
