import ContactDetail from './ContactDetail.js';
import { getArrayOfObjectsFromDto as getTypedArray, getValueFromDto as getValue } from './_helpers.js';

export default class CompanyLocation {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.street1 = getValue(dto, 'street1', 'string', '');
		this.street2 = getValue(dto, 'street2', 'string', '');
		this.city = getValue(dto, 'city', 'string', '');
		this.township = getValue(dto, 'township', 'string', '');
		this.county = getValue(dto, 'county', 'string', '');
		this.state = getValue(dto, 'state', 'string', '');
		this.postalCode = getValue(dto, 'postalCode', 'string', '');
		this.country = getValue(dto, 'country', 'string', '');
		this.latitude = getValue(dto, 'latitude', 'number', 0);
		this.longitude = getValue(dto, 'longitude', 'number', 0);
		this.phoneNumbers = getTypedArray(dto, 'phoneNumbers', ContactDetail, []);
		this.faxNumbers = getTypedArray(dto, 'faxNumbers', ContactDetail, []);
		this.emailAddresses = getTypedArray(dto, 'emailAddresses', ContactDetail, []);
	}

	get address() {
		return (this.street1 ? this.street1 + ', ' : '') + (this.street2 ? this.street2 + ', ' : '') + (this.city ? this.city + ', ' : '') + this.state + ' ' + this.postalCode;
	}

	get addressLine1() {
		return (this.street1 ? this.street1 + ', ' : '') + (this.street2 ? ', ' + this.street2 + ', ' : '')
	}

	get addressLine2() {
		return (this.city ? this.city + ', ' : '') + this.state + ' ' + this.postalCode;
	}
}
