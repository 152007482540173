import { url as urlHelper } from '@/helpers/helpers';
import { getIdb, idbHelpers, localChanges, localChangesInUse } from '@/idb';
import Customer from '@/models/Customer';
import FileReference from '@/models/FileReference';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import PaginatedList from '@/models/PaginatedList';
import { fetchWrap, idbResponse, isAborted, isIdbResponse, offlineResponse, setPagination } from '../_helpers';
import filesApi from './files';

export default {
	/**
	 * Get paginated customers
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of Customer objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ filter = undefined, sort = undefined, limit = undefined, start = undefined } = {}, abortSignal) {
		const allowedSorts = ['name-asc', 'name-desc', 'overdue-desc'];
		const allowedFilters = ['', 'residential', 'commercial', 'delinquent'];
		const query = setPagination(limit, start);
		if (typeof filter === 'string' && allowedFilters.includes(filter)) {
			query.filter = filter;
		} else {
			query.filter = allowedFilters[0];
		}
		if (typeof sort === 'string' && allowedSorts.includes(sort)) {
			query.sort = sort;
		} else {
			query.sort = allowedSorts[0];
		}
		const url = urlHelper('/api/Customers', query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new Customer(x));
		} else {
			throw response;
		}
	},
	/**
	 * Search customers
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of Customer objects if the request was successful, otherwise a Response.
	 */
	async search({ search = undefined, limit = undefined, start = undefined } = {}, abortSignal) {
		const query = setPagination(limit, start);
		if (typeof search === 'string' && search) {
			query.search = search;
		}
		const url = urlHelper('/api/Customers/Search', query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new Customer(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a customer
	 * @param {Number} id Customer ID
	 * @returns (async) Returns a Customer if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response, data;
		const idb = localChangesInUse.value ? await getIdb() : null;
		if (idb) { ({ response, data } = await localChanges.getDataIfChanged(idb, 'customers', id)); }
		try {
			if (!response) {
				response = await fetchWrap('/api/Customers/' + id);
				if (response.ok) { data = await response.json(); }
			}
		} catch {
			if (idb) {
				data = await idb.get('customers', id);
				response = data ? idbResponse(200) : idbResponse(404);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				if (await idbHelpers.putIfExists(idb, 'customers', data)) {
					const fileMap = {};
					data.locations.forEach(x => x.attachments.forEach(y => fileMap[y.id] = y));
					await filesApi.storeInIdb(Object.values(fileMap));
				}
			}
			return new Customer(data);
		} else {
			throw response;
		}
	},
	/**
	 * Get a customer
	 * @param {Number} id Customer Location ID
	 * @returns (async) Returns a Customer if the request was successful, otherwise a Response.
	 */
	async getByLocationId(id) {
		let response, data;
		try {
			response = await fetchWrap('/api/Customers/ByLocation/' + id);
			if (response.ok) { data = await response.json(); }
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Customer(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a customer
	 * @param {Customer} model customer to create.
	 * @returns (async) Returns the new Customer if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/Customers', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Customer(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a customer
	 * @param {Customer} model customer to update.
	 * @returns (async) Returns the updated Customer if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/Customers/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Customer(model);
		} else {
			return response;
		}
	},
	/**
	 * Update a customer by adding images to a customer location.
	 * @param {Number} customerId
	 * @param {Number} customerLocationId
	 * @param {FileReference[]} files
	 * @returns (async) Returns the Response.
	 */
	async addImagesToLocation(customerId, customerLocationId, files) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		await filesApi.mapCleanLocalIds(files);
		let response;
		try {
			response = await fetchWrap('/api/Customers/AddImages/' + customerId + '/' + customerLocationId, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(files),
			});
		} catch {
			if (idb) {
				const c = await idb.get('customers', customerId);
				const cl = c ? c.locations.find(x => x.id === customerLocationId) : null;
				if (cl) {
					// update data in idb
					for (const file of files) {
						if (!cl.attachments.find(x => x.id === file.id)) {
							cl.attachments.push(file.toDto());
						}
					}
					await idb.put('customers', c);
					// store local change in idb
					const change = await localChanges.get(idb, LocalChange.getKey('customers', customerId)) ?? new LocalChange({ storeName: 'customers', id: customerId, state: LocalChangeState.modified, data: {} });
					change.error = null;
					if (!change.data.addImages) { change.data.addImages = {}; }
					let changeData = change.data.addImages[customerLocationId.toString()];
					if (!changeData) { changeData = change.data.addImages[customerLocationId.toString()] = {}; }
					if (!Array.isArray(changeData.files)) {
						changeData.files = [];
					}
					for (const file of files) {
						if (!changeData.files.find(x => x.id === file.id)) {
							changeData.files.push(JSON.parse(JSON.stringify(file)));
						}
					}
					await localChanges.add(idb, change);
					response = idbResponse(204);
				} else {
					response = offlineResponse();
				}
			} else {
				response = offlineResponse();
			}
		}
		return response;
	},
	/**
	 * Merge 2 customers.
	 * @param {Customer} model customer to create.
	 * @returns (async) Returns the Response.
	 */
	async merge({ sourceId, targetId }) {
		const model = { sourceId, targetId };
		let response;
		try {
			response = await fetchWrap('/api/Customers/Merge', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Delete a customer
	 * @param {Number} id Customer ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the customer could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Customers/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
