import { getIdb, idbHelpers } from '@/idb';
import CompanyLocation from '@/models/CompanyLocation';
import { DateTime } from 'luxon';
import { fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';
import cache from './cache';

const idbStore = 'companyLocations';

export default {
	/**
	 * Get the current company locations
	 * @returns (async) Returns an array of CompanyLocations if the request was successful, otherwise null.
	 */
	async getAll() {
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchWrap('/api/CompanyLocations');
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.getAll(idbStore);
			response = idbResponse(200);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idbHelpers.replaceAll(idb, idbStore, data);
				await cache.setTimestamp(idbStore, timestamp);
			}
			return data.map(x => new CompanyLocation(x));
		} else {
			throw response;
		}
	},
	/**
	 * Update the current company locations
	 * @param {CompanyLocation[]} models Company locations to update.
	 * @returns (async) Returns an array of CompanyLocations if the request was successful, otherwise null.
	 */
	async updateAll(models) {
		let response, timestamp;
		try {
			response = await fetchWrap('/api/CompanyLocations/All', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(models),
			});
			// timestamp after response since the request invalidates the cache
			timestamp = DateTime.now();
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const idb = await getIdb();
			const data = await response.json();
			if (idb && !isIdbResponse(response)) {
				await idbHelpers.replaceAll(idb, idbStore, data);
				await cache.setTimestamp(idbStore, timestamp);
			}
			return data.map(x => new CompanyLocation(x));
		} else {
			return response;
		}
	},
};
